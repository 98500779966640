
.ui-notification > .message {
    word-break: break-word;
}

.lp-icon {
    color: #9E9E9E;
}

.lp-icon:hover {
    color: #000000;
}

.lp-icon-avatar {
    width: 1.1em !important;
    height: 1.1em !important;
    font-size: 48px;
    margin-top: 0;
}

.lp-font-list-label {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.005em;
    line-height: 24px;
}

.lp-font-list-content {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.010em;
    line-height: 20px;
    color: grey;
}

/* YASQE editor */
lp-yasqe.readOnly > .yasqe > .CodeMirror {
    background-color: #E0E0E0;
}

.scroll-watch-list-tail {
    margin-top: 2REM;
    text-align: center;
    min-height: 4REM;
    line-height: 4REM;
    cursor: pointer;
}

.scroll-watch-list-tail:hover {
    background-color: #DADADA;
}

/* Remove blue outline after component is clicked. */
.scroll-watch-list-tail:focus {
    outline: none;
}

/* Pipeline list. */

.sub-menu {
    display: none;
    position: fixed;
    bottom: 70px;
    right: 30px;
    padding-top: 10px;
    padding-bottom: 40px;
}

.pipelines_list_add {
    position: fixed;
    bottom: 30px;
    right: 30px;
}

.pipelines_list_add:hover .sub-menu {
    display: block;
}

/* Pipeline canvas. */

.no-left-right-padding {
    padding-left: 0;
    padding-right: 0;
}

.no-margin {
    margin: 0;
}

#canvas {
    padding-left: 5px;
    padding-right: 5px;
}

#canvas svg {
    background-color: white;
    border-style: solid;
    border-width: 1px;
    height: -moz-calc(100vh - 169px); /* Firefox */
    height: -webkit-calc(100vh - 169px); /* Chrome, Safari */
    height: calc(100vh - 169px); /* IE9+ and future browsers */
}

/* Joint modification. */

.available-magnet {
    fill: yellow;
}

.available-cell rect {
    stroke-dasharray: 5, 2;
}

.component-selection {
    position: fixed;
    pointer-events: none;
    border: 1px dashed #000;
    border-radius: 5px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
    z-index: 1;
}

.component-selection .action {
    pointer-events: auto;
    cursor: pointer;
}

.joint-paper > svg {
    position: relative;
}

/* Execution list. */

.table_row_wait_for_delete {
    opacity: 0.2;
    transition: opacity 2s linear;
}

/* Center text in progress bar. */

.progress {
    position: relative;
}

.progress span {
    position: absolute;
    display: block;
    width: 100%;
    color: black;
    text-align: center;
}

/* Pipeline edit. */

#componentMenu .top-line .delete {
    background-color: #FF2020;
}

#componentMenu .top-line .delete md-icon {
    color: white;
}
